@import "../config";

.style-accordion {
    margin: 20px 20px 20px 0px;
    // overflow-x: scroll;
}

@media screen and (max-width:768px) {}

.accordion-body {
    display: flex;
}

@media screen and (max-width: 768px) {
    .style-accordion {
        margin: 20px 0px !important;
    }

    .accordion-body {
        display: flex;
        flex-direction: column;
    }

    .accordion-table-header {
        table {
            margin-left: 0px !important;
        }
    }
}

.payment-table {
    flex: 2;
    margin: 10px;

    .rate-details-responsive {
        width: 100%;

        table {
            width: 100%;
            background-color: $bg-accordion;
            text-align: center;

            th {
                background-color: $theme-contrast;
                color: $secondary-color
            }

            tr,
            td,
            th {
                border: 1px solid $primary-color;
            }
        }
    }
}

.rate-table{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .btn-edit-billing-info{
        width: 30%;
        border: 0;
        border-radius: 5px;
        background-color: $theme-contrast;
        color: $secondary-color;
    }
}

.billing-info{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    table{
        border: 1px solid $primary-color;
        tr,th,td{
            border: 1px solid $primary-color;
        }
    }
    .btn-remove-billing-info{
        width: 30%;
        border: 0;
        border-radius: 5px;
        background-color: $theme-contrast;
        color: $secondary-color;
    }
}
.payment-history {
    flex: 2;
    margin: 10px;

    .border-payment-history {
        border: 2px solid $theme-color;
        background-color: $theme-color-light;
        color: $secondary-color;
        padding: 10px;
    }
}

.payment-history-table {
    border: 1px solid $secondary-color;
    width: 100%;
    text-align: center;

    thead tr {
        background-color: $secondary-color;
        color: $theme-color
    }

    th,
    tr,
    td {
        border: 1px solid $secondary-color;
    }
}

.style-payment-footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .calculations-payment {
        display: flex;
        flex-direction: column;
    }

    .payment-button {
        // border: 1px solid white;
        align-self: flex-end;
    }
}

.style-payment-date {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-show {
    background-color: $secondary-color;
    color: $primary-color;
    border-radius: 3px;
    border: 0;
}

.btn-confirm {
    border: 0;
    background-color: $theme-btn-color;
    color: $secondary-color;
}

.btn-finalise {
    border: 0;
    background-color: $theme-btn-color;
    color: $secondary-color;
    margin-right: 5px;
}

.btn-cancel {
    border: 0;
    background-color: $danger-color;
    color: $secondary-color;
}

.edit-table {
    width: 100% !important;
}

.edit-input {
    width: 100% !important;
}

.accordion-table-header {
    table {
        margin-left: 15px;
        width: calc(100% - 10px);
        thead tr {
            height: 50px;
            background-color: $theme-color;
            color: $secondary-color;
        }

        thead tr th {
            padding-left: 5px;
        }
    }
}

.extend-width-50 {
    width: 50px !important;
}

.extend-width-150 {
    width: 150px !important;
}

.extend-width-200 {
    width: 200px !important;
}

.extend-width-pax {
    width: 100px !important;
}

@media screen and (max-width: 660px) {
    .extend-width-50 {
        width: auto !important;
    }

    .extend-width-150 {
        width: auto !important;
    }

    .extend-width-200 {
        width: auto !important;
    }

    .extend-width-pax {
        width: auto !important;
    }

}

.responsive-accordion-table {
    width: 100%;

    table {
        width: 100%;
        color: $primary-color;
        border: 2px solid $theme-color;

        td {
            border: 2px solid $theme-color;
            padding: 10px;
        }

        tbody tr {
            background-color: $bg-accordion;
        }
    }
}

.editable-fields{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .edit-billing{
        label{
            width: 40%;
        }
    }
}