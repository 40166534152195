@import "../config";

@mixin sticky-sibebar {
    position: sticky;
    top: 0;
    left: 0;
}

@mixin margin-sidebar {
    margin: 05px 10px;
}

@mixin buttons {
    margin: 05px 10px;
    height: 30px;
    border-radius: 5px;
}

@mixin btn-theme ($bg, $color) {
    border: 0;
    background-color: $bg;
    color: $color
}
@media screen and (max-width: 768px) {
    .credit-info {
        height: auto !important;
    }
}

@media screen and (min-width: 768px) {
    .credit-info {
        min-height: 100vh !important;
    }
}
.payment-modal{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}
.dropdown-filter{
    display: flex;
    flex-direction: column;
}
.credit-page {
    display: flex;
    height: 100%;

    .credit-info {
        @include sticky-sibebar;
        flex: 1;
        background-color: $bg-sidebar;
        gap: 20px;
        h5 {
            @include margin-sidebar;
        }

        .dropdown-toggle {
            margin: 05px 10px;
            width: 90%;
            background-color: $theme-color;
            border: $theme-color;

            &:hover {
                background-color: $theme-color-light;
            }
        }

        .credit-remaining {
            @include margin-sidebar;
        }

        .show-calculation {
            @include margin-sidebar;
        }

        

        .btn-cancel {

            @include buttons;
            @include btn-theme ($danger-color, $secondary-color);
        }
    }

    .credit-border-bottom {
        padding: 10px 0px;
        border-bottom: 2px solid yellow;
    }

    .same-width {
        display: flex;

        p {
            width: 70%;
        }
    }

    .flex-datepicker {
        display: flex;
        gap: 10px;
        margin: 0px 10px;
        align-items: center;
        // flex-direction: column;
    }

    .make-credit {
        flex: 4;
        background-color: $bg-color;

        .background-credit {
            .filter {
                display: flex;
                align-items: center;
                gap: 2px;
                margin: 05px 10px;

                label {
                    text-transform: uppercase;

                }
            }

        }
        .show-filter{
            padding: 30px 0px;
            // background-color: $secondary-color;
            border-top: 2px solid yellow;
            border-bottom: 2px solid yellow;
        }
        .custom-filter {
            height: auto;
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 20px;
            // justify-content: space-between;
            // flex-direction: column;
            .input-search {
                background-color: $secondary-color;
                display: flex;
                align-items: center;
                border-radius: 2px;
                border: 1px solid black;

                .dropdown-search{
                    .select-search-input{
                        border: 0 !important;
                        width: 100% !important;
                    }
                    .select-search-select{
                        width: 100% !important;
                    }
                }

                .eyeglass-icon {
                    height: 35px;
                    background-color: $secondary-color;
                    // color: $secondary-color;
                    // border-radius: 8%;
                    font-size: 22px;
                }
            }
        }
        .credit-customer{
            background-color: $secondary-color;
            border: 1px solid $primary-color;
            .select-search-input{
                border: 0 !important;
                width: 100% !important;
                height: 35px !important;
            }
            .select-search-select{
                width: 100% !important;
            }
        }
    }
}

.payment-history, .bill-list{
    border-bottom: 1px solid $theme-color
}
.btn-pay {
    @include buttons;
    @include btn-theme ($green, $secondary-color);
}
.btn-filter-credit {
    @include btn-theme($theme-color, $secondary-color);
    border-radius: 5px;
    min-height: 35px;
    height: auto;
    margin-top: 15px;
}

.btn-details{
    border: 0;
    height: 30px;
    @include btn-theme($secondary-theme, $secondary-color);
    border-radius: 5px;
    margin-top: 5px;
    &:hover, &:focus{
        @include btn-theme($btn-credit-hover, $secondary-color);
    }

}
.react-datepicker__header {
    background-color: $theme-color;
}

.toggle-category {
    .dropdown-toggle {
        width: 200px;
        @include btn-theme ($secondary-theme, $secondary-color);
    }
}

@media screen and (max-width: 942px) {
    .custom-filter {
        flex-direction: column;
        align-items: start !important;
    }
    .flex-datepicker {
        align-items: start !important;
        flex-direction: column !important;
    }
}

