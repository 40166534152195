@import "./config";
.btn-customer-width{
    width: 100%;
    height: 50px;
}
.btn-customer-info{
    display: flex;
    float: right;
}
.Register, .Reset, .Save, .Verify, .okTest{
    border-radius: 5px;
    text-align: center;
    width: 100px;
    height: 35px;
    margin: 5px 10px;
}
.btn{
    color: $secondary-color !important;
}
.Save{
    margin: 20px 10px;
    background-color: $success-color;
}
.Register{
    background-color: $theme-contrast;
}
.Reset , .oktest{
    background-color: $theme-color;
}
.Verify{
    background-color: $basic-theme;
}
