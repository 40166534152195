@import "../config";

@mixin color-theme ($background-color, $text-color) {
    background-color: $background-color;
    color: $text-color;
}

.customerList {
    display: flex;
    justify-content: center;
    margin: 10px;

    .enter {
        margin-right: 10px;
    }
}

.customerDetailsTable {
    margin: 10px 20px;

    table {
        border: 1px solid $primary-color;
        text-align: center;

        tr,
        th,
        td {
            border: 1px solid $primary-color;
        }
    }

    .cilcikable-tr {
        &:focus {
            @include color-theme($theme-color, $secondary-color);
        }
    }
}

.customer-details-buttons {
    display: flex;
    justify-content: end;
    gap: 20px;
}

.btn-special {
    @include color-theme($secondary-theme, $secondary-color);
    border-radius: 10px;
    border: 0;
    height: 40px;
}

.btn-updates {
    border-radius: 10px;
    border: 0;
    @include color-theme($bg-availability, $secondary-color);
}

.btn-update-info {
    border: 0;
    @include color-theme($bg-availability, $secondary-color);
}

.btn-close-update {
    border: 0;
    @include color-theme($bg-sidebar, $secondary-color);
}

.modal-body {
    margin: 10px 25px !important;
}

.customer-edit-fileds {
    display: flex;
    flex-direction: column;
    gap: 10px;
}