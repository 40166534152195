@import "./config";

.select-search-input, .select-search-select{
    width: 190px !important;
    border: 1px solid $primary-color !important;
}
.select-search-input{
    height: 30px !important;
}

.select-search-row{
    height: auto !important;
}
.select-search-options{
    z-index: 100 !important;
}