$theme-color: rgb(152,45,37);
$secondary-theme:#ECA800;
$primary-color: black;
$secondary-color: rgb(255,255,255);
$danger-color:hsla(0, 100%, 50%, 0.849);
$success-color:rgb(19, 160, 6);
$warning-color:rgb(255, 255, 0);
$bg-filter:rgb(231, 205, 166);
$bg-availability:rgb(143,146,59);
$btn-credit: rgb(9,60,124);
$bg-sidebar: #dfd4d4bd;
$theme-color-light: rgb(156, 66, 60);
$green:rgb(174,174,24);
$yellow: rgb(255, 238, 0);
$btn-credit-hover: rgb(133, 135, 187);

$theme-btn-color: rgb(177, 163, 4);
$basic-theme: rgb(177,169,62);
$theme-contrast:rgb(226,155,63);
$bg-accordion:rgb(242,226,201);
$basic-theme-light: rgb(190, 102, 109);
$bg-color: rgb(214, 218, 226);
// $btn-credit: rgb(133, 79, 141);
