.selectbuttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 10px;
}

.selectedmonthname{
    background-color: darkred;
    color: white;
    text-align: center;
    font-size: 20px;
    
}

.entireMonthselectedDiv{
    margin: 10px 30px;
    .table-responsive{
        border: 2px solid black;
    }
}